<template>
  <div>
    <div id="cmpPartidaFactura" class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          <span v-show="modo == 'nueva'">Nueva partida</span>
          <span v-show="modo == 'edicion'">Edición de partida</span>
          de factura
        </h4>
      </div>
      <div class="card-body">
        <div class="divSeleccionProducto" v-show="partidaVisible == false">
          <div class="input-group">
            <input
              name="dato"
              class="form-control"
              ref="dato"
              v-model="dato"
              @keyup.enter="filtrarProductos()"
              placeholder="Dato del producto"
            />
            <button
              class="btn btn-success bg-gradient"
              @click="filtrarProductos()"
            >
              Filtrar
              <i class="bx bx-filter-alt ms-1"></i>
            </button>
          </div>

          <div class="conTblProductos table-responsive">
            <table class="tblProductos table table-hover table-striped align-middle table-nowrap">
              <thead>
                <tr>
                  <th style="width: 70px">#ID</th>
                  <th>Nombre</th>
                  <th>Precio</th>
                  <th style="width: 140px" class="text-center">Seleccionar</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(producto, index) in productos" :key="producto.id">
                  <td>{{ producto.id }}</td>
                  <td>{{ producto.nombre }}</td>
                  <td class="text-right">
                    {{ hrr.fmon(producto.precio, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
                  </td>
                  <td class="text-center">
                    <button
                      type="button"
                      class="btn btn-success btn-sm"
                      v-on:click="seleccionarProducto(index)"
                    >
                      <i class="mdi mdi-plus-thick"></i>
                      Seleccionar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="divPartida" v-show="partidaVisible">
          <div class="row">
            <div class="col-lg-2 col-md-4 col-sm-4 col-6">
              <label>#ID</label>
              <input
                name="id"
                class="form-control text-right"
                placeholder="0000"
                v-model="partida.id_producto"
                readonly
              />
            </div>
            <div class="col-lg-3 col-md-4 col-sm-4 col-6">
              <label>Nombre</label>
              <input
                name="nombre"
                placeholder="Nombre"
                class="form-control"
                v-model="partida.nombre"
                readonly
              />
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4 col-6">
              <label>Precio</label>
              <div class="input-group">
                <span class="input-group-text">
                  {{ monedaSistema.simbolo }}
                </span>
                <input
                  name="precio"
                  class="form-control text-right"
                  v-model="partida.precio"
                  @change="actualizarSubtotal()"
                  :readonly="partida.precio_modificable == false"
                />
                <span class="input-group-text">
                  {{ monedaSistema.codigo }}
                </span>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4 col-6">
              <label>Cantidad</label>
              <input
                name="cantidad"
                class="form-control text-right"
                type="number"
                v-model="partida.cantidad"
                @change="actualizarSubtotal()"
              />
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4 col-6">
              <label>SubTotal</label>
              <div class="input-group">
                <span class="input-group-text">
                  {{ monedaSistema.simbolo }}
                </span>
                <input
                  name="sub_total"
                  class="form-control text-right"
                  v-model="partida.sub_total"
                  readonly
                />
                <span class="input-group-text">
                  {{ monedaSistema.codigo }}
                </span>
              </div>
            </div>
            <div class="col-lg-1 col-md-4 col-sm-4 col-6 pt-4">
              <button
                class="btnCambiarProducto btn btn-danger"
                @click="cambiarProducto()"
              >
                <i class="mdi mdi-close-thick"></i>
              </button>
            </div>
          </div>
          <br />

          <label>Nota</label>
          <div class="row">
            <div class="col-lg-12">
              <textarea
                name="nota"
                rows="4"
                placeholder="Nota adicional de la nueva partida"
                class="form-control"
                v-model="partida.nota"
              ></textarea>
            </div>
          </div>

          <br />
          <div class="row">
            <!-- CARGOS -->
            <div class="col-md-6">
              <div class="row">
                <div class="col-xs-9">
                  <label>+ Cargos</label>&nbsp;
                  <div class="form-check form-switch" style="display: inline-block;">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="cmpPartidaFactura_palancaCargos"
                      :checked="cargosActivos"
                      @change="palancaCargos()"
                    />
                    <label
                      class="form-check-label"
                      for="cmpPartidaFactura_palancaCargos"
                    ></label>
                  </div>
                </div>
                <div class="col-xs-3 text-right">
                  <button
                    class="btn btn-outline-success btn-sm"
                    v-show="cargosActivos"
                    @click="agregarCargo()"
                  >
                    <i class="mdi mdi-plus-thick"></i>
                    Nuevo
                  </button>
                </div>
              </div>
              <div class="conTblCargos table-responsive" v-show="cargosActivos">
                <table class="tblCargos table table-hover table-striped">
                  <thead>
                    <tr>
                      <th><strong>Concepto</strong></th>
                      <th style="width: 100px"><strong>%</strong></th>
                      <th style="width: 100px"><strong>{{ monedaSistema.simbolo }}</strong></th>
                      <th style="width: 60px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(cargo, index) in partida.cargos"
                      :key="cargo.id"
                    >
                      <td>
                        <input
                          type="text"
                          name="concepto"
                          placeholder="Concepto del cargo"
                          class="form-control"
                          v-model="cargo.concepto"
                        />
                      </td>
                      <td>
                        <input
                          name="porcentaje"
                          class="form-control text-right"
                          placeholder="0"
                          v-model="cargo.porcentaje"
                          @change="actualizarValorCargo(index)"
                        />
                      </td>
                      <td>
                        <input
                          name="valor"
                          class="form-control text-right"
                          placeholder="0"
                          v-model="cargo.valor"
                          @change="
                            cargo.valor = parseFloat(cargo.valor).toFixed(3),
                            actualizarPorcentajeCargo(index)
                          "
                        />
                      </td>
                      <td>
                        <button
                          type="button"
                          class="btn btn-danger"
                          @click="eliminarCargo(index)"
                        >
                          <i class="mdi mdi-close-thick"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- DESCUENTOS -->
            <div class="col-md-6">
              <div class="row">
                <div class="col-xs-9">
                  <label>- Descuentos</label>&nbsp;
                  <div class="form-check form-switch" style="display: inline-block;">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="cmpPartidaFactura_palancaDescuentos"
                      :checked="descuentosActivos"
                      @change="palancaDescuentos()"
                    />
                    <label
                      class="form-check-label"
                      for="cmpPartidaFactura_palancaDescuentos"
                    ></label>
                  </div>
                </div>
                <div class="col-xs-3 text-right">
                  <button
                    class="btn btn-outline-success btn-sm"
                    v-show="descuentosActivos"
                    @click="agregarDescuento()"
                  >
                    <i class="mdi mdi-plus-thick"></i>
                    Nuevo
                  </button>
                </div>
              </div>

              <div
                class="conTblDescuentos table-responsive"
                v-show="descuentosActivos"
              >
                <table class="tblDescuentos table table-hover table-striped">
                  <thead>
                    <tr>
                      <th><strong>Concepto</strong></th>
                      <th style="width: 100px"><strong>%</strong></th>
                      <th style="width: 100px"><strong>{{ monedaSistema.simbolo }}</strong></th>
                      <th style="width: 60px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(descuento, index) in partida.descuentos"
                      :key="descuento.id"
                    >
                      <td>
                        <input
                          type="text"
                          name="concepto"
                          placeholder="Concepto del descuento"
                          class="form-control"
                          v-model="descuento.concepto"
                        />
                      </td>
                      <td>
                        <input
                          name="porcentaje"
                          class="form-control text-right"
                          placeholder="0"
                          v-model="descuento.porcentaje"
                          @change="actualizarValorDescuento(index)"
                        />
                      </td>
                      <td>
                        <input
                          name="valor"
                          class="form-control text-right"
                          placeholder="0"
                          v-model="descuento.valor"
                          @change="
                            descuento.valor = parseFloat(descuento.valor).toFixed(3),
                            actualizarPorcentajeDescuento(index)
                          "
                        />
                      </td>
                      <td>
                        <button
                          type="button"
                          class="btn btn-danger"
                          @click="eliminarDescuento(index)"
                        >
                          <i class="mdi mdi-close-thick"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <br />

          <div class="row">
            <label
              class="offset-md-8 col-md-2 offset-sm-6 col-sm-2 offset-xs-2 col-xs-4 text-right"
            >
              + Cargos
            </label>
            <div class="col-md-2 col-sm-4 col-xs-6 text-right">
              {{ hrr.fmon(partida.valor_total_cargos, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
            </div>
          </div>

          <div class="row">
            <label
              class="offset-md-8 col-md-2 offset-sm-6 col-sm-2 offset-xs-2 col-xs-4 text-right"
            >
              - Descuentos
            </label>
            <div class="col-md-2 col-sm-4 col-xs-6 text-right">
              {{ hrr.fmon(partida.valor_total_descuentos, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
            </div>
          </div>

          <div class="row">
            <label
              class="offset-md-8 col-md-2 offset-sm-6 col-sm-2 offset-xs-2 col-xs-4 text-right"
            >
              Total
            </label>
            <div class="col-md-2 col-sm-4 col-xs-6 text-right">
              {{ hrr.fmon(partida.total, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-right">
      <div class="btn-group">
        <button class="btn btn-danger" @click="cancelar()">
          <i class="mdi mdi-cancel"></i>
          Cancelar
        </button>
        <button
          class="btn btn-success"
          @click="actualizarPartida()"
          v-if="modo == 'edicion'"
        >
          <i class="mdi mdi-content-save"></i> Actualizar partida
        </button>
        <button
          class="btn btn-success"
          @click="registrarPartida()"
          v-if="modo == 'nueva' && partida.id_producto != null"
        >
          <i class="mdi mdi-plus-thick"></i> Registrar partida
        </button>
      </div>
    </div>
    <br>
  </div>
</template>

<script>
import accounting from 'accounting/accounting.js'
import ProductoSrv from '@/services/ProductoSrv.js'

export default {
  name: 'CmpPartidaFactura',
  props: ['modo', 'partidaEditar'],
  data() {
    return {
      hrr: iu.hrr, // Objeto Herramienta
      cargosActivos: false,
      dato: '',
      descuentosActivos: false,
      partidaVisible: false,

      partida: {
        id_producto: null,
        nombre: '',
        precio: 0,
        cantidad: 0,
        sub_total: 0,
        nota: '',
        cargos: [],
        descuentos: [],
        valor_total_cargos: 0,
        valor_total_descuentos: 0,
        total: 0
      },
      partidaInicial: {},
      productos: []
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  created: function() {
    var self = this

    // Copia de la partida
    this.partidaInicial = Object.assign({}, this.partida)

    // Activación del modo edición
    if (self.modo == 'edicion') {
      self.partidaVisible = true

      self.partida = self.partidaEditar
      if (self.partida.cargos.length > 0) self.cargosActivos = true
      if (self.partida.descuentos.length > 0) self.descuentosActivos = true
    } 
  },

  methods: {
    actualizarCargosYDescuentos: function() {
      var self = this
      this.partida.cargos.forEach(function(cargo, index) {
        self.actualizarValorCargo(index)
      })

      this.partida.descuentos.forEach(function(descuento, index) {
        self.actualizarValorDescuento(index)
      })
    },

    actualizarPorcentajeCargo: function(index) {
      this.partida.cargos[index].porcentaje = 0

      this.actualizarTotales()
    },

    actualizarPorcentajeDescuento: function(index) {
      this.partida.descuentos[index].porcentaje = 0

      this.actualizarTotales()
    },

    actualizarSubtotal: function() {
      this.partida.sub_total = this.partida.precio * this.partida.cantidad
      this.actualizarCargosYDescuentos()
      this.actualizarTotales()
    },

    actualizarTotales: function() {
      var subTotal = parseFloat(this.partida.sub_total),
        totalCargos = 0,
        totalDescuentos = 0,
        total = 0

      this.partida.cargos.forEach(function(cargo) {
        totalCargos += parseFloat(cargo.valor)
      })

      this.partida.descuentos.forEach(function(descuento) {
        totalDescuentos += parseFloat(descuento.valor)
      })

      total = subTotal + totalCargos - totalDescuentos

      this.partida.valor_total_cargos = totalCargos
      this.partida.valor_total_descuentos = totalDescuentos
      this.partida.total = total
    },

    actualizarValorCargo: function(index) {
      var subTotal = parseFloat(this.partida.sub_total)
      var totalCargos = 0
      var porcentaje = this.partida.cargos[index].porcentaje

      // Si el porcentaje es 0, se evita continuar con la actualización,
      // por que es un cargo estático
      if (porcentaje == 0) return

      this.partida.cargos.forEach(function(cargo, indexAux) {
        if (indexAux >= index) return
        totalCargos += parseFloat(cargo.valor)
      })

      var valorAux = subTotal + totalCargos

      this.partida.cargos[index].valor = parseFloat(
        (valorAux / 100) * porcentaje
      ).toFixed(2)

      this.actualizarTotales()
    },

    actualizarValorDescuento: function(index) {
      var subTotal = parseFloat(this.partida.sub_total)
      var totalDescuentos = 0
      var porcentaje = this.partida.descuentos[index].porcentaje

      // Si el porcentaje es 0, se evita continuar con la actualización,
      // por que es un descuento estático
      if (porcentaje == 0) return

      this.partida.descuentos.forEach(function(descuento, indexAux) {
        if (indexAux >= index) return
        totalDescuentos += parseFloat(descuento.valor)
      })

      var valorAux = subTotal - totalDescuentos
      this.partida.descuentos[index].valor = parseFloat(
        (valorAux / 100) * porcentaje
      ).toFixed(2)

      this.actualizarTotales()
    },

    actualizarPartida: function() {
      this.$emit('actualizar', { partida: this.partida })
      this.limpiarPartida()
    },

    agregarCargo: function() {
      this.partida.cargos.push({
        concepto: '',
        porcentaje: 0,
        valor: 0
      })
    },

    agregarDescuento: function() {
      this.partida.descuentos.push({
        concepto: '',
        porcentaje: 0,
        valor: 0
      })
    },

    cambiarProducto: function() {
      this.partidaVisible = false
    },

    cancelar: function() {
      this.limpiarPartida()

      switch (this.modo) {
        case 'nueva':
          this.$emit('cerrar')
          break
        case 'edicion':
          this.$emit('devolver')
          break
      }
    },

    /**
     * Método que determina si los conceptos de descuento no se encuentran en blanco
     */
    conceptosCargosCorrectos: function() {
      var correctos = true
      this.partida.cargos.forEach(function(cargo) {
        if (cargo.concepto == '') correctos = false
      })

      return correctos
    },

    /**
     * Método que determina si los conceptos de descuento no se encuentran en blanco
     */
    conceptosDescuentosCorrectos: function() {
      var correctos = true
      this.partida.descuentos.forEach(function(descuento) {
        if (descuento.concepto == '') correctos = false
      })

      return correctos
    },

    eliminarCargo: function(index) {
      this.partida.cargos.splice(index, 1)
      this.actualizarTotales()
    },

    eliminarDescuento: function(index) {
      this.partida.descuentos.splice(index, 1)
      this.actualizarTotales()
    },

    filtrarProductos: function() {
      var self = this
      let params = { dato: this.dato }

      ProductoSrv.productosJSON(params).then(response => {
        let res = response.data
        self.productos = res.productos

        // Actualización de los precios del producto a solo 2 decimales de presición
        self.productos.forEach(function(p) {
          p.precio = parseFloat(p.precio).toFixed(2)
        })
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    limpiarPartida: function() {
      this.dato = ''
      this.partidaVisible = false

      this.partida = Object.assign({}, this.partidaInicial)
      this.partida.cargos = []
      this.partida.descuentos = []
      this.productos = []

      this.cargosActivos = false
      this.descuentosActivos = false
    },

    palancaCargos: function() {
      var self = this

      if (self.cargosActivos) self.cargosActivos = false
      else self.cargosActivos = true

      if (self.cargosActivos == false) {
        self.partida.cargos = []
        this.actualizarTotales()
      }
    },

    palancaDescuentos: function() {
      var self = this

      if (self.descuentosActivos) self.descuentosActivos = false
      else self.descuentosActivos = true

      if (self.descuentosActivos == false) {
        self.partida.descuentos = []
        this.actualizarTotales()
      }
    },

    registrarPartida: function() {
      if (!this.conceptosCargosCorrectos()) {
        iu.msg.warning('Los conceptos de los cargos no deben quedar en blanco')
        return
      }

      if (!this.conceptosDescuentosCorrectos()) {
        iu.msg.warning(
          'Los conceptos de los descuentos no deben quedar en blanco'
        )
        return
      }

      var partida = Object.assign({}, this.partida)
      this.$emit('registrar', { partida: partida })

      this.limpiarPartida()
    },

    seleccionarProducto: function(index) {
      this.partidaVisible = true
      var producto = this.productos[index]

      this.partida.id_producto = producto.id
      this.partida.nombre = producto.nombre
      this.partida.precio = producto.precio
      this.partida.precio = parseFloat(this.partida.precio).toFixed(2)
      this.partida.precio_modificable = producto.precio_modificable
      this.partida.cantidad = 1
      this.partida.sub_total = this.partida.precio
      this.partida.valor_total_cargos = 0
      this.partida.valor_total_descuentos = 0
      this.partida.total = this.partida.precio

      this.actualizarTotales()
    }
  },
}
</script>

<style scoped>

</style>