<template>
  <div class="modal fade" ref="mdlInfoContrato" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content" style="border:none">
        <div class="modal-header text-white bg-info">
          <div class="modal-title text-center">
            <strong style="font-size:30px">Información del contrato {{contrato.id}}</strong>
          </div>
          <button
            type="button" class="btn-close"
            data-bs-dismiss="modal" aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row h5">
            <div class="col-md-6">
              <strong>Servicio:</strong> {{contrato.servicio.nombre}}
            </div>
            <div class="col-md-6">
              <strong>Precio:</strong> {{formatoMoneda(contrato.precio_servicio)}}
            </div>
          </div>
          <div class="row h5">
            <div class="col-md-6">
              <strong>Tipo de renta:</strong> {{contrato.id_tipo_renta == 1 ? 'Mensual fija' : 'Mensual variable'}}
            </div>
            <div class="col-md-6">
              <strong>Modalidad del servicio:</strong> {{contrato.prepago ? 'Prepago' : 'Pospago'}}
            </div>
          </div>
          <div class="row h5">
            <div class="col-md-6">
              <strong>Dia de pago: </strong>
              <template v-if="contrato.id_tipo_renta == 1">
                <span v-if="contrato.dia_pago == 0">
                  {{contrato.prepago == true ? 'Mismo día del inicio de periodo': 'Mismo día del fin de periodo'}}
                </span>
                <span v-if="contrato.dia_pago >= 1 && contrato.dia_pago <= 30">
                  {{contrato.dia_pago}} 
                  {{contrato.prepago == true ? `día${contrato.dia_pago>1?'s':''} después del inicio de periodo`: `día${contrato.dia_pago>1?'s':''} antes del fin de periodo`}}
                </span>
                <span v-if="contrato.dia_pago == 31">
                  {{contrato.prepago == true ? 'Último día del periodo': 'Inicio del periodo'}}
                </span>
              </template>
              <template v-if="contrato.id_tipo_renta == 2">
                Día siguiente al final del periodo
              </template>
            </div>
            <div class="col-md-6">
              <strong>Dia de corte del servicio:</strong>
              <template v-if="contrato.id_tipo_renta == 1">
                <span v-if="contrato.n_dias_corte == 0">
                  El mismo día de pago
                </span>
                <span v-if="contrato.n_dias_corte > 0">
                  {{ contrato.n_dias_corte }} día{{ contrato.n_dias_corte > 1 ? 's' : '' }} después del día de pago
                </span>
              </template>
              <template v-if="contrato.id_tipo_renta == 2">
                Día siguiente al final del periodo
              </template>
            </div>
          </div>
          <div class="row h5">
            <div class="col-md-12">
              <strong>Crear factura:</strong>
              {{contrato.n_dias_generar_factura}}
              <template v-if="contrato.id_tipo_renta == 1">
                día{{ contrato.n_dias_generar_factura > 1 ? 's' : '' }} antes del día de pago
              </template>
              <template v-if="contrato.id_tipo_renta == 2">
                antes del final del periodo
              </template>
            </div>
          </div>
          <div class="row h5">
            <div class="col-md-6">
              <strong>Periodo:</strong>
              {{contrato.inicio_periodo}} al {{contrato.fin_periodo}}
            </div>
            <div class="col-md-6">
              <strong>Inicio de periodo:</strong>
              <template v-if="contrato.id_tipo_renta == 1">
                Día {{ contrato.dia_inicio_periodo }} del mes
              </template>
              <template v-if="contrato.id_tipo_renta == 2">
                Indefinido
              </template>
            </div>
          </div>
          <div class="row h5">
            <div class="col-md-6">
              <strong>Cargo por morosidad:</strong> {{contrato.cargo_morosidad ? 'Si' : 'No'}}
            </div>
            <div class="col-md-6">
              <strong>Notificación por correo:</strong> {{contrato.notificacion_correo ? 'Si' : 'No'}}
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            <i class="mdi mdi-close-thick"></i>
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal';
import ContratoSrv from '@/services/ContratoSrv.js'
import accounting from 'accounting/accounting.js'

export default {
  name: 'MdlInfoContrato',
  data() {
    return {
      id_contrato: null,
      contrato: {
        cpe:{},
        isp:{},
        router:{},
        servicio:{},
        servicios_complementarios:{}
      }
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  methods: {
    cargarContrato: function(idContrato){
      var self = this

      ContratoSrv.contratoJSON(idContrato).then(response => {
        self.contrato = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se puedo cargar el contrato '+idContrato
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    formatoMoneda: function(numero) {
      return accounting.formatMoney(numero, { symbol: this.monedaSistema.simbolo, miles: ",", decimal: "." })+' '+this.monedaSistema.codigo
    },
    mostrar(idContrato) {
      var self = this
      self.id_contrato = idContrato
      self.cargarContrato(idContrato)

      var modal = new Modal(self.$refs.mdlInfoContrato)
      modal.show()
    }
  },
}
</script>

<style scoped>

</style>