import Layout from '@/views/layouts/main'
import CmpSeleccionCliente from './CmpSeleccionCliente.vue'
import CmpPartidaFactura from './CmpPartidaFactura.vue'
import FacturaSrv from '@/services/FacturaSrv.js'
import ClienteSrv from '@/services/ClienteSrv.js'
import SistemaSrv from '@/services/SistemaSrv.js'
import SerieFacturasSrv from '@/services/SerieFacturasSrv.js'
import MdlInfoContrato from './MdlInfoContrato.vue'

import Swal from 'sweetalert2'

import moment from 'moment'
export default {
  name: 'EdicionFactura',
  components: { 
    Layout,
    CmpSeleccionCliente,
    CmpPartidaFactura,
    MdlInfoContrato
  },
  data() {
    return {
      animproc: false,
      hrr: iu.hrr, // Objeto Herramienta
      modoFactura: 'nueva',
      idFactura: null,
      factura: {
        partidas: [],
        cargos: [],
        descuentos: [],
        id_cliente: null,
        id_contrato: 0,
        id_serie: null,
        fecha: '',
        fecha_para_pago: null,
        fecha_vencimiento: null,
        fecha_para_pago_contrato: null,
        inicio_periodo: null,
        fin_periodo: null,
        nombre_cliente: '',
        nota: '',
        sub_total: 0,
        valor_total_cargos: 0,
        valor_total_descuentos: 0,
        iva: 0,
        total: 0,
        pospago: 1,
        id_tipo_renta_contrato: 1,
        generada_por_sistema: false
      },
      fechaParaPagoActiva: false,
      fechaVencimientoActiva: false,

      facturaInicial: {},

      modoPartida: 'nueva',
      partidaEditar: {},
      indexPartidaEditar: null,

      series: [],
      piva: 0, // Porcentaje de IVA
      cargosActivos: false,
      descuentosActivos: false,

      facturaVisible: true,
      partidaFacturaVisible: false,
      seleccionClienteVisible: false,

      idsContratos: []
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  created: function() {
    var self = this

    // Actualización del modo de trabajo
    self.idFactura = this.$route.params.id_factura
    if (self.idFactura != undefined) {
      self.modoFactura = 'edicion'
      self.cargarFactura()
    }

    // Cargas iniciales

    // Si se trata de una factura de pago contra entrega,
    // se establece la bandera "pospago" en false
    if (this.$route.query.pospago == false) this.factura.pospago = false

    self.cargarFecha()
    self.cargarSeries(function() {
      // Selección de la serie por defecto
      if (self.series.length > 0) {
        self.factura.id_serie = self.series[0].id

        // Copia inicial de la factura
        self.facturaInicial = Object.assign({}, self.factura)
      } else {
        iu.msg.warning('Es necesario crear al menos una serie')
        self.$router.push({ name: 'listadoSeriesFacturas' })
      }
    })
    self.cargarPorcentajeIva()

    if (self.$route.params.id_cliente != undefined && self.$route.params.id_cliente != '' && self.$route.params.id_cliente != null) {
      self.factura.id_cliente = self.$route.params.id_cliente
      self.cargarCliente(function() {
        self.cargarIdsContratosCliente(self.$route.params.id_cliente)
      })
    }
  },
  watch: {
    'factura.fecha_para_pago': function(newFecha, olvFecha){
      var self = this

      if(olvFecha == null) return

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      // Si es una factura generada por sistema y es para un contrato de renta mensual fija (RMF)
      if(self.factura.generada_por_sistema && self.factura.id_tipo_renta_contrato == 1 && 
        self.factura.fecha_para_pago_contrato != newFecha) {
        swal.fire({
          title: "Cambio de fecha de pago",
          html: `<p class="text-justify">Estás a punto de cambiar la fecha de pago de la factura que fue generada para un contrato, `+
            `y la fecha <strong>${moment(newFecha,'YYYY-MM-DD').format("DD/MM/YYYY")}</strong> no coincide con la fecha de pago del contrato <strong>${self.factura.fecha_para_pago_contrato}</strong>. <br><br>`+
            `Si continúas con el cambio el sistema volverá a generar una factura que coincida con su fecha de pago. <br><br>` +
            `Si lo que necesitas es dar más tiempo al cliente para pagar la factura asigna una PROMESA DE PAGO y manten `+
            `la fecha de pago intacta. ¿Deseas continuar con el cambio de fecha de pago?<p>`,
          icon: "question",
          confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
          cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
          showCancelButton: true
        }).then(result => {
          if (result.value) {
            self.factura.fecha_para_pago = newFecha
            swal.fire("Cambiada!", "Se cambio correctamente", "success");
          } else if ( result.dismiss === Swal.DismissReason.cancel ) {
            self.factura.fecha_para_pago = self.factura.fecha_para_pago_contrato
          }
        })
      } else { // En todos los demás casos
        self.factura.fecha_para_pago = newFecha
      }
    }
  },

  methods: {
    /**
     * Método que se encarga de actualizar la factura
     */
     actualizar() {
      var self = this,
        factura = Object.assign({}, self.factura)

      let inicio_periodo = moment(factura.inicio_periodo,'YYYY-MM-DD').format("YYYY-MM-DD")
      inicio_periodo = moment(inicio_periodo).unix()

      let fin_periodo = moment(factura.fin_periodo,'YYYY-MM-DD').format("YYYY-MM-DD")
      fin_periodo = moment(fin_periodo).unix()

      if(fin_periodo <= inicio_periodo){
        iu.msg.warning("El periodo final no puede ser una fecha antes o igual a su periodo inicial")
        return
      }

      // Formateo de fechas de la factura antes de enviarla
      Object.assign(factura, {
        fecha_para_pago: factura.fecha_para_pago ? moment(factura.fecha_para_pago, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
        fecha_vencimiento: factura.fecha_vencimiento ? moment(factura.fecha_vencimiento, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
        inicio_periodo: factura.inicio_periodo ? moment(factura.inicio_periodo, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
        fin_periodo: factura.fin_periodo ? moment(factura.fin_periodo, 'YYYY-MM-DD').format('YYYY-MM-DD') : null
      })
      delete factura.fecha_para_pago_contrato

      self.animproc = true

      FacturaSrv.actualizar(factura).then(response => {
        iu.msg.success('Se actualizó correctamente')
        self.cargarFactura()
        self.animproc = false
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar'
        }
        iu.msg.error(mensaje)
        console.log(error)
        self.animproc = false
      })
    },

    /**
     * Método para actualizar los cargos con porcentaje asignado
     */
    actualizarCargos: function() {
      var self = this
      this.factura.cargos.forEach(function(cargo, index) {
        // Si el cargo no tiene un porcenaje asignado, se evita hacer el cálculo
        if (cargo.porcentaje == 0) return

        // Si tiene un porcentaje asignado, se hace el cálculo
        self.actualizarValorCargo(index)
      })
    },

    /**
     * Método para actualizar los descuentos con porcentaje asignado
     */
    actualizarDescuentos: function() {
      var self = this
      this.factura.descuentos.forEach(function(descuento, index) {
        // Si el descuento no tiene un porcenaje asignado, se evita hacer el cálculo
        if (descuento.porcentaje == 0) return

        // Si tiene un porcentaje asignado, se hace el cálculo
        self.actualizarValorDescuento(index)
      })
    },

    actualizarPartida: function(event) {
      var partida = event.partida,
        index = this.indexPartidaEditar

      this.factura.partidas.splice(index, 1, partida)

      this.facturaVisible = true
      this.partidaFacturaVisible = false

      this.actualizarCargos()
      this.actualizarDescuentos()
      this.actualizarTotales()
    },

    actualizarPorcentajeCargo: function(index) {
      this.factura.cargos[index].porcentaje = 0

      this.actualizarTotales()
    },

    actualizarPorcentajeDescuento: function(index) {
      this.factura.descuentos[index].porcentaje = 0

      this.actualizarTotales()
    },

    actualizarTotales: function() {
      var subTotal = 0,
        totalCargos = 0,
        totalDescuentos = 0,
        totalIva = 0,
        totalTmp = 0,
        total = 0

      // Cálculo del subtotal
      this.factura.partidas.forEach(function(partida) {
        subTotal += parseFloat(partida.total)
      })

      this.factura.cargos.forEach(function(cargo) {
        totalCargos += parseFloat(cargo.valor)
      })

      this.factura.descuentos.forEach(function(descuento) {
        totalDescuentos += parseFloat(descuento.valor)
      })

      totalTmp = subTotal + totalCargos - totalDescuentos
      totalIva = parseFloat((this.piva * totalTmp) / 100).toFixed(2)
      total = parseFloat(totalTmp) + parseFloat(totalIva)

      this.factura.sub_total = subTotal
      this.factura.valor_total_cargos = totalCargos
      this.factura.valor_total_descuentos = totalDescuentos
      this.factura.iva = totalIva
      this.factura.total = total
    },

    actualizarValorCargo: function(index) {
      var subTotal = 0
      var totalCargos = 0
      var porcentaje = this.factura.cargos[index].porcentaje

      // Cálculo del subtotal
      this.factura.partidas.forEach(function(partida, indexAux) {
        subTotal += parseFloat(partida.total)
      })

      this.factura.cargos.forEach(function(cargo, indexAux) {
        if (indexAux >= index) return
        totalCargos += parseFloat(cargo.valor)
      })

      var valorAux = subTotal + totalCargos
      this.factura.cargos[index].valor = parseFloat(
        (valorAux / 100) * porcentaje
      ).toFixed(3)

      this.actualizarTotales()
    },

    actualizarValorDescuento: function(index) {
      var subTotal = 0
      var totalDescuentos = 0
      var porcentaje = this.factura.descuentos[index].porcentaje

      // Cálculo del subtotal
      this.factura.partidas.forEach(function(partida, indexAux) {
        subTotal += parseFloat(partida.total)
      })

      this.factura.descuentos.forEach(function(descuento, indexAux) {
        if (indexAux >= index) return
        totalDescuentos += parseFloat(descuento.valor)
      })

      var valorAux = subTotal - totalDescuentos
      this.factura.descuentos[index].valor = parseFloat(
        (valorAux / 100) * porcentaje
      ).toFixed(3)

      this.actualizarTotales()
    },

    agregarCargo: function() {
      this.factura.cargos.push({
        concepto: '',
        porcentaje: 0,
        valor: 0
      })
    },

    agregarDescuento: function() {
      this.factura.descuentos.push({
        concepto: '',
        porcentaje: 0,
        valor: 0
      })
    },

    asignarCliente: function($event) {
      this.factura.id_cliente = $event.id
      this.factura.numero_cliente = $event.numero
      this.factura.nombre_cliente = $event.nombre
      this.seleccionClienteVisible = false
      this.facturaVisible = true

      this.cargarIdsContratosCliente(this.factura.id_cliente)
    },

    atras: function() {
      this.$router.go(-1)
    },

    cancelar: function() {
      this.atras()
    },

    cancelarSeleccionCliente: function() {
      this.seleccionClienteVisible = false
      this.facturaVisible = true
    },

    cargarCliente: function(callback) {
      var self = this
      let idCliente = self.factura.id_cliente

      ClienteSrv.clienteJSON(idCliente).then(response => {
        let cliente = response.data
        self.factura.nombre_cliente =
          cliente.nombre +
          ' ' +
          cliente.apellido_paterno +
          ' ' +
          cliente.apellido_materno
        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    cargarFactura() {
      var self = this,
        params = {con_fecha_para_pago_contrato: true, con_id_tipo_renta_contrato: true}

      FacturaSrv.facturaJSON(self.idFactura, params).then(response => {
        let factura = response.data
        // Carga de la factura local
        Object.assign(self.factura, factura)
        Object.assign(self.factura, {
          fecha: moment(factura.fecha, 'YYYY-MM-DD').format('DD/MM/YYYY'),
          fecha_para_pago: moment(factura.fecha_para_pago, 'YYYY-MM-DD').format('YYYY-MM-DD'),
          fecha_para_pago_contrato: moment(factura.fecha_para_pago_contrato, 'YYYY-MM-DD').format('YYYY-MM-DD'),
          fecha_vencimiento: factura.fecha_vencimiento ? moment(factura.fecha_vencimiento, 'YYYY-MM-DD').format('YYYY-MM-DD'): null,
          inicio_periodo: factura.inicio_periodo ? moment(factura.inicio_periodo, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
          fin_periodo: factura.fin_periodo ? moment(factura.fin_periodo, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
        })

        self.factura.id_cliente = factura.cliente.id
        self.factura.nombre_cliente = factura.cliente.nombre

        delete self.factura.cliente

        // Actualización de las partidas para ser compatibles con la factura local
        self.factura.partidas.forEach(function(partida) {
          partida.id_producto = partida.producto.id
          partida.nombre = partida.producto.nombre

          delete partida.producto
        })

        // Habilitación de las palancas de fecha de pago y vencimiento
        if (
          self.factura.fecha_para_pago != null &&
          self.fecha_para_pago != '' &&
          self.fechaParaPagoActiva == false
        )
          self.fechaParaPagoActiva = true
        if (
          self.factura.fecha_vencimiento != null &&
          self.fecha_vencimiento != '' && 
          self.fechaVencimientoActiva == false
        )
          self.fechaVencimientoActiva = true

        // Habilitación de las palancas de carga y descuentos
        if ( self.factura.cargos.length > 0 && self.cargosActivos == false ) {
          self.cargosActivos = true
        }
        if ( self.factura.descuentos.length > 0 && self.descuentosActivos == false ) {
          self.descuentosActivos = true
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    cargarFecha: function() {
      var self = this

      SistemaSrv.fecha({ formato: 'd/m/Y' }).then(response => {
        self.factura.fecha = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    cargarIdsContratosCliente: function(idCliente) {
      var self = this

      ClienteSrv.idsContratos(idCliente).then(response => {
        self.idsContratos = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    cargarSeries: function(callback) {
      var self = this
      SerieFacturasSrv.seriesJSON().then(response => {
        self.series = response.data
        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    cargarPorcentajeIva: function() {
      var self = this

      SistemaSrv.valorAjuste('iva').then( response => {
        self.piva = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    cerrar: function() {
      this.atras()
    },

    cerrarPartidaFactura: function() {
      this.partidaFacturaVisible = false
      this.facturaVisible = true
    },

    /**
     * Método que determina si los conceptos de descuento no se encuentran en blanco
     */
    conceptosCargosCorrectos: function() {
      var correctos = true
      this.factura.cargos.forEach(function(cargo) {
        if (cargo.concepto == '') correctos = false
      })

      return correctos
    },

    /**
     * Método que determina si los conceptos de descuento no se encuentran en blanco
     */
    conceptosDescuentosCorrectos: function() {
      var correctos = true
      this.factura.descuentos.forEach(function(descuento) {
        if (descuento.concepto == '') correctos = false
      })

      return correctos
    },

    devolverPartida: function() {
      this.facturaVisible = true
      this.partidaFacturaVisible = false
    },

    editarPartida: function(index) {
      var partida = Object.assign({}, this.factura.partidas[index])

      this.partidaFacturaVisible = true
      this.facturaVisible = false

      this.modoPartida = 'edicion'
      this.partidaEditar = partida
      this.indexPartidaEditar = index
    },

    eliminarCargo: function(index) {
      this.factura.cargos.splice(index, 1)
      this.actualizarTotales()
    },

    eliminarDescuento: function(index) {
      this.factura.descuentos.splice(index, 1)
      this.actualizarTotales()
    },

    eliminarPartida: function(index) {
      this.factura.partidas.splice(index, 1)

      this.actualizarCargos()
      this.actualizarDescuentos()
      this.actualizarTotales()
    },

    /**
     * Método que devuelve una cantidad en formato de moneda
     * @param {*} numero
     */
    fmon: function(numero) {
      return iu.hrr.fmon(numero)
    },

    /**
     * Método que genera e imprime una factura que es pagada al
     * momento de entregar
     */
    generar: function() {
      var self = this
      // Guardado de la factura
      this.guardar(function(idFactura) {
        // Impresión de la factura
        self.$router.replace({
          name: 'facturaRapida',
          params: { id: idFactura },
          query: {
            pospago: false
          }
        })
      })
    },

    guardar(callback) {
      var self = this
      var factura = Object.assign({}, self.factura)

      // Verificación de datos importante
      if (factura.id_serie == null) {
        iu.msg.warning('Es necesario seleccionar una serie')
        return
      }

      if (factura.id_cliente == null) {
        iu.msg.warning('Es necesario seleccionar un cliente')
        return
      }

      if (factura.partidas.length == 0) {
        iu.msg.warning('Es necesario registrar al menos una partida')
        return
      }

      if (!this.conceptosCargosCorrectos()) {
        iu.msg.warning('Los conceptos de los cargos no deben quedar en blanco')
        return
      }

      if (!this.conceptosDescuentosCorrectos()) {
        iu.msg.warning(
          'Los conceptos de los descuentos no deben quedar en blanco'
        )
        return
      }

      let inicio_periodo = moment(factura.inicio_periodo,'YYYY-MM-DD').format("YYYY-MM-DD")
      inicio_periodo = moment(inicio_periodo).unix()

      let fin_periodo = moment(factura.fin_periodo,'YYYY-MM-DD').format("YYYY-MM-DD")
      fin_periodo = moment(fin_periodo).unix()

      if(fin_periodo <= inicio_periodo){
        iu.msg.warning("El periodo final no puede ser una fecha antes o igual a su periodo inicial")
        return
      }

      if (factura.id_contrato == 0) factura.id_contrato = null

      self.animproc = true

      FacturaSrv.guardar(factura).then(response => {
        let id = response.data
        iu.msg.success('Se guardó correctamente la factura')
        self.limpiar()
        self.animproc = false

        if (callback) callback(id)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo registrar la factura'
        }
        iu.msg.error(mensaje)
        console.log(error)
        self.animproc = false
      })
    },

    infoContrato:function(idContrato){
      if(idContrato != null && idContrato != 0) this.$refs.mdlInfoCrt.mostrar(idContrato)
      else iu.msg.warning('No se ha seleccionado ningún contrato')
    },

    limpiar: function() {
      this.factura = Object.assign(this.factura, this.facturaInicial)
      this.factura.partidas = []
      
      this.cargosActivos = false
      this.descuentosActivos = false

      this.actualizarTotales()
    },

    mostrarNuevaPartida: function() {
      this.modoPartida = 'nueva'
      this.partidaFacturaVisible = true
      this.facturaVisible = false
    },

    mostrarSeleccionCliente: function() {
      this.facturaVisible = false
      this.seleccionClienteVisible = true
    },

    palancaCargos: function() {
      var self = this

      if (self.cargosActivos) self.cargosActivos = false
      else self.cargosActivos = true

      if (self.cargosActivos == false) {
        self.factura.cargos = []
        this.actualizarTotales()
      }
    },

    palancaDescuentos: function() {
      var self = this

      if (self.descuentosActivos) self.descuentosActivos = false
      else self.descuentosActivos = true

      if (self.descuentosActivos == false) {
        self.factura.descuentos = []
        this.actualizarTotales()
      }
    },

    palancaFechaParaPago: function() {
      var self = this

      if (self.fechaParaPagoActiva) self.fechaParaPagoActiva = false
      else self.fechaParaPagoActiva = true

      if (self.fechaParaPagoActiva == false) self.factura.fecha_para_pago = null
    },

    palancaFechaVencimiento: function() {
      var self = this

      if (self.fechaVencimientoActiva) self.fechaVencimientoActiva = false
      else self.fechaVencimientoActiva = true

      if (self.fechaVencimientoActiva == false)
        self.factura.fecha_vencimiento = null
    },

    registrarNuevaPartida: function(event) {
      this.factura.partidas.push(event.partida)
      this.facturaVisible = true
      this.partidaFacturaVisible = false

      this.actualizarCargos()
      this.actualizarDescuentos()
      this.actualizarTotales()
    }
  }
}