import axios from 'axios'
import API from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/productos`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  activar(idProducto) {
    return apiClient.post(`${idProducto}/activar`)
  },

  actualizar(producto) {
    return new Promise((resolve, reject) => {
      var data = new FormData()

      // Carga de los datos en el form data
      Object.keys(producto).map(function(key) {
        if (key == 'fotos') return // Evita guardar la fotos, se guardarán más adelante
        data.append(key, producto[key])
      })

      var ifp = -1 // Indice de la foto principal

      // Carga de los IDs de fotos registradas
      var idsFotos = []
      producto.fotos.forEach(function(foto, index) {
        if (foto.principal) ifp = index // Actualización del indice que tiene la foto principal

        if (foto.id == null || foto.id == undefined) return
        data.append('ids_fotos[]', foto.id) //Agrega el ID de la foto al arreglo
      })

      producto.fotos.forEach(function(foto, index) {
        // Si la foto no tiene un File agregado entonces se evita agregarlo al
        // arreglo principal de fotos
        if (foto.file == null || foto.file == undefined) return
        data.append('fotos[]', foto.file)
      })

      data.append('principal', ifp) // Carga del indice de la foto principal

      var req = new XMLHttpRequest()
      req.open('POST', API + '/productos/' + producto.id, true)
      req.setRequestHeader(
        'Authorization',
        localStorage.getItem('argusblack.token')
      )

      req.onreadystatechange = function() {
        if (req.readyState == 4) {
          if (req.status == 200) {
            resolve({ data: req.responseText })
          } else if (req.status == 500) {
            reject({
              response: {
                data: req.responseText
              }
            })
          }
        }
      }

      req.send(data)
    })
  },

  desactivar(idProducto) {
    return apiClient.post(`${idProducto}/desactivar`)
  },

  eliminar(idProducto) {
    return apiClient.delete(`${idProducto}`)
  },

  fotosJSON(idProducto) {
    return apiClient.get(`${idProducto}/fotos.json`)
  },

  guardar(producto) {
    return new Promise(function(resolve, reject) {
      var data = new FormData()

      // Carga de los datos en el form data
      Object.keys(producto).map(function(key) {
        if (key == 'fotos') return // Evita guardar la fotos, se guardarán más adelante
        data.append(key, producto[key])
      })

      // Carga de las fotos
      var ifp = -1 // Indice de la foto principal
      producto.fotos.forEach(function(foto, index) {
        if (foto.principal) ifp = index // Actualización del indice que tiene la foto principal

        // Si la foto no tiene un File agregado entonces se evita agregarlo al
        // arreglo principal de fotos
        if (foto.file == null || foto.file == undefined) return
        data.append('fotos[]', foto.file)
      })

      data.append('principal', ifp) // Carga del indice de la foto principal

      var req = new XMLHttpRequest()
      req.open('POST', API + '/productos', true)
      req.setRequestHeader(
        'Authorization',
        localStorage.getItem('argusblack.token')
      )

      req.onreadystatechange = function() {
        if (req.readyState == 4) {
          if (req.status == 200) {
            var id = req.responseText
            resolve({ data: id })
          } else if (req.status == 500) {
            reject({
              response: {
                data: req.responseText
              }
            })
          }
        }
      }

      req.send(data)
    })
  },

  productoJSON(idProducto) {
    return apiClient.get(`${idProducto}.json`)
  },

  productosJSON(params) {
    return apiClient.get(`${API}/productos.json`, { params: params })
  },

  planServicioJSON(idPlan) {
    return apiClient.get(`planes-servicio/${idPlan}.json`)
  },

  planesServicioJSON(params) {
    return apiClient.get('planes-servicio.json', { params: params })
  },

  serviciosJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/servicios.json`, { params: params })
  }
}
