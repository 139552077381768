<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              Selección de cliente para factura
            </h4>
          </div>
          <div class="card-body">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                v-model="dato_cliente"
                v-on:keyup.enter="refrescarClientes()"
                placeholder="Dato del cliente"
              />
              <button class="btn btn-success bg-gradient" @click="refrescarClientes()">
                Filtrar
                <i class="bx bx-filter-alt ms-2"></i>
              </button>
            </div>

            <div class="table-responsive">
              <table class="table align-middle table-nowrap">
                <thead>
                  <tr>
                    <th>Número</th>
                    <th>Nombre</th>
                    <th class="text-center">Acción</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="cliente in clientes" :key="cliente.id">
                    <td>{{ cliente.numero }}</td>
                    <td>
                      {{ cliente.nombre }}
                      {{ cliente.apellido_paterno }}
                      {{ cliente.apellido_materno }}
                    </td>
                    <td class="text-center">
                      <button
                        class="btn btn-success btn-sm"
                        @click="
                          seleccionar(
                            cliente.id,
                            cliente.numero,
                            cliente.nombre +
                            ' ' +
                            cliente.apellido_paterno +
                            ' ' +
                            cliente.apellido_materno
                          )
                        "
                      >
                        <i class="mdi mdi-account-check-outline"></i>
                        Seleccionar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="text-right">
          <button class="btn btn-outline-danger" @click="cancelar()">
            <i class="mdi mdi-cancel"></i>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClienteSrv from '@/services/ClienteSrv.js'
export default {
  name: 'CmpSeleccionCliente',
  data() {
    return {
      dato_cliente: '',
      clientes: []
    }
  },
  methods: {
    cancelar: function() {
      this.$emit('cancelar')
    },

    refrescarClientes: function() {
      var self = this

      if (self.dato_cliente == '') {
        iu.msg.warning(
          'Es necesario un dato del cliente para hacer el filtrado'
        )
        return
      }

      let params = { dato_cliente: self.dato_cliente, activos: true }

      ClienteSrv.clientesJSON(params).then(response => {
        let res = response.data
        self.clientes = res.clientes
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    seleccionar: function(id, numero, nombre) {
      this.$emit('cliente-seleccionado', {
        id: id,
        numero: numero,
        nombre: nombre
      })
    }
  }
}
</script>

<style scoped>

</style>